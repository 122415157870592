import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function handleError(error, toast) {
  if (error.response && error.response.data.message) {
    toast({
      component: ToastificationContent,
      props: {
        title: error.response.data.message,
        icon: 'EditIcon',
        variant: 'danger',
      },
    })
  } else {
    toast({
      component: ToastificationContent,
      props: {
        title: typeof error === 'string' ? error : 'Encountered an unknown error.',
        icon: 'EditIcon',
        variant: 'danger',
      },
    })
  }
}
